<template>
  <div class="ErrorCard">
    <md-card class="md-layout-item">
      <md-card-content class="card-content">
        <i class="material-icons md-36 u-red error-icon">error</i>
        <p class="o-default-h4 u-text-center u-display-ib u-red error-text">{{ mainMessage }} {{ optionalMessage }}</p>
      </md-card-content>
    </md-card>
  </div> <!-- /.ErrorCard -->
</template>

<script>
export default {
  name: 'ErrorCard',
  props: {
    mainMessage: String,
    optionalMessage: String
  }
}
</script>

<style lang="scss" scoped>
.ErrorCard {

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .md-card-content {
    padding-bottom: 1.6rem;
  }

  .error-icon {
    padding: 0 .8rem;
  }

  .error-text {
    line-height: 2.6rem;
    vertical-align: top;
  }
}
</style>