<template>
  <div class="Loader">
    <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    <div class="loader-slot">
      <slot></slot>
    </div>
    <!-- <div class="loader-slot">
      <slot></slot>
    </div> WIP - @todo When payment is ready -->
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss">
.Loader {
  text-align: center;

  .md-progress-spinner {
    margin: 4rem auto;
  }

  .loader-slot {
    margin: 2rem auto;
  }
}
</style>
