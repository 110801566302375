<template>
  <nav>
    <ul class="PaginationBlock">
      <li v-for="page in pages"
          :data-test="`page-link-${page}`"
          :key="page"
          :class="paginationClass(page)"
          @click.prevent="changePage(page);">
        <a class="page-link" href v-text="page"></a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'PaginationBlock',
  props: {
    pages: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    changePage(goToPage) {
      if (goToPage === this.currentPage) return;
      if (window.location.pathname != '/my-account/user-domains' && window.location.pathname != '/domain-names') {
        this.$router.replace({ path:`/auctions`, query: { sourcePageNumberAuctions: `${goToPage}` } });
      }
      this.$emit('update:currentPage', goToPage);
    },
    paginationClass(page) {
      return {
        'page-item': true,
        active: this.currentPage === page
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.PaginationBlock {

  .page-item {
    cursor: pointer;
    display: inline-block;
    margin: .8rem 0;

    &.active {
      background-color: $palette-primary-color-three;
      color: $white !important;
      font-weight: bold;
    }

    a {
      @include default-body-xs;
      padding: 1.6rem;
      text-align: center;
      &:hover {
        background-color: $palette-primary-color-three;
        color: $white !important;
        text-decoration: underline;
      }
    }
  }
}
</style>
